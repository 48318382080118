import React from 'react';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import submissionReceivedImg from 'styles/images/submission_received.png';
import { useForm, FormProvider } from 'react-hook-form';
import { BroadcastFormResponse, ScaleType } from 'types/BroadcastForm';
import useLatest from 'react-use/lib/useLatest';
import { AxiosError } from 'axios';
import { ProgressBar } from 'react-bootstrap';
import { FormQuestion } from './FormQuestion';

interface Props {
  formId: string
  sessionId: string
  form: BroadcastFormResponse
  onSuccess: () => void
}

export const BroadcastSurveyForm: React.FC<Props> = ({
  formId, sessionId, form, onSuccess,
}) => {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [submitError, setSubmitError] = React.useState('');
  const useFormProps = useForm({
    mode: 'onChange',
  });

  const {
    handleSubmit, formState, getValues, control, reset, trigger,
  } = useFormProps;
  const { isValid, isSubmitting } = formState;
  const [questionNo, setQuestionNo] = React.useState(1);
  const [values, setValues] = React.useState({});

  const latestValues = useLatest(values);
  React.useEffect(() => {
    reset(latestValues.current);
  }, [questionNo, reset, latestValues]);

  const onSubmit = handleSubmit(async (lastStepData) => {
    const formData = {
      ...values,
      ...lastStepData,
    }

    const payload = {
      Response: form.Questions.flatMap(
        ({ QuestionId, ScaleType: questionScaleType, Options }) => {
          const selectedOptionId = formData[QuestionId].OptionId;

          if (Array.isArray(selectedOptionId)) {
            // If selectedOptionId is an array, create an entry for each value
            return selectedOptionId.map((optionId) => {
              const hasText = questionScaleType === ScaleType.OpenText
          || (Options.find((item) => item.OptionId === optionId)?.WithTextInput ?? false);
              return {
                QuestionId,
                ResponseOptionId: optionId,
                ResponseText: hasText
                  ? formData[QuestionId].Text
                  : null,
              };
            });
          }

          // If selectedOptionId is a single value
          const hasText = questionScaleType === ScaleType.OpenText
            || (Options.find((item) => item.OptionId === selectedOptionId)?.WithTextInput ?? false);
          return {
            QuestionId,
            ResponseOptionId: selectedOptionId,
            ResponseText: hasText
              ? formData[QuestionId].Text
              : null,
          };
        },
      ),
    };

    setSubmitError('');
    try {
      await BroadcastFormApi.submitBroadcastForm(
        formId,
        sessionId,
        payload,
        {
          // to prevent from redirecting
          ignoreGlobalCatch: true,
        },
      );
      setIsSuccess(true);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err) {
      const errorMessage = (err as AxiosError).response?.data?.Message
      ?? 'Something went wrong';
      setSubmitError(errorMessage);
      setIsSuccess(false);
    }
  });

  if (form.Responses?.length) {
    return <p className="broadcast-form-session__info">You have already completed this survey</p>;
  }

  if (isSuccess) {
    return (
      <div className="broadcast-form-session__submission-received">
        <img
          src={submissionReceivedImg}
          alt="thank you check icon"
        />
        <h1>Thank You!</h1>
        <p>
          Your submission has been received.
        </p>
      </div>
    )
  }

  const question = form.Questions[questionNo - 1];
  const isLastQuestion = questionNo === form.Questions.length;

  return (
    <>
      {form.Branding.BrandLogoUrl && (
        <img
          src={form.Branding.BrandLogoUrl}
          alt=""
          className="broadcast-form-session__logo"
        />
      )}
      <form
        className="broadcast-form-session__form"
      >
        <FormProvider {...useFormProps}>
          <header className="broadcast-form-session__header">
            {form.Form.Name}
          </header>
          <fieldset className="broadcast-form-session__fieldset">
            <ProgressBar
              now={questionNo}
              max={form.Questions.length}
              label={`${questionNo}/${form.Questions.length}`}
              className="broadcast-form-session__progressbar"
            />

            <FormQuestion
              question={question}
              key={question.QuestionId}
              control={control}
              trigger={trigger}
              getValues={getValues}
            />

          </fieldset>
          <footer className="broadcast-form-session__footer">
            <button
              type="button"
              disabled={questionNo === 1}
              className="btn btn-secondary-dynamic"
              onClick={() => {
                const newValues = {
                  ...values,
                  ...getValues(),
                }
                setValues(newValues);
                setQuestionNo(questionNo - 1);
              }}
            >
              Back
            </button>

            {isLastQuestion
              ? (
                <button
                  type="button"
                  disabled={!isValid || isSubmitting}
                  className="btn btn-primary-dynamic ml-auto"
                  onClick={onSubmit}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="button"
                  disabled={!isValid}
                  className="btn btn-primary-dynamic ml-auto"
                  onClick={() => {
                    const newValues = {
                      ...values,
                      ...getValues(),
                    }
                    setValues(newValues);
                    setQuestionNo(questionNo + 1);
                  }}
                >
                  Next
                </button>
              )}

          </footer>
          {submitError && <p className="broadcast-form-session__submit-error">{submitError}</p>}
        </FormProvider>
      </form>
    </>
  );
};
