import useSWR, { SWRConfiguration, Fetcher } from 'swr';
import {
  useQueryParam, NumberParam, StringParam,
} from 'use-query-params';
import * as InboxApi from 'api/InboxAPI';
import { SmsAutoReplyPayload } from 'types/ISmsAutoReplies';

export const useSmsAutoReplyQuery = (
  options: SWRConfiguration<
  SmsAutoReplyPayload, any, Fetcher<SmsAutoReplyPayload> | undefined
  > = {},
) => {
  const [pageParam = 1] = useQueryParam('page', NumberParam);
  const [perPageParam = 10] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const [searchFieldParam] = useQueryParam('searchField', StringParam);
  const [searchValueParam] = useQueryParam('searchValue', StringParam);

  const fetcher = async ([
    ,
    searchField,
    searchValue,
    sortField,
    sortDirection,
    page,
    perpage,
  ]) => InboxApi.getSmsAutoReplies({
    searchField,
    searchValue,
    sortField,
    sortDirection,
    page,
    perpage,
  });

  return useSWR<SmsAutoReplyPayload>([
    'sms-auto-reply',
    searchFieldParam,
    searchValueParam,
    sortFieldParam,
    sortDirectionParam,
    pageParam,
    perPageParam,
  ],
  fetcher,
  );
}
