import React, {
  DetailedHTMLProps, InputHTMLAttributes, forwardRef,
} from 'react';
import clsx from 'clsx';
import uuidv4 from 'uuid/v4';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as Check } from 'styles/images/check.svg';

export interface ICheckboxProps
  extends DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  > {
  name: string
  label?: string
  className?: string
  customIcon?: JSX.Element
  customIconTooltip?: string
}

const Checkbox = forwardRef<HTMLInputElement, ICheckboxProps>(
  ({
    id, name, label, className = '', style = {}, customIcon, customIconTooltip, ...props
  }, ref) => {
    const inputId = id;

    return (
      <div className={clsx('Checkbox form-check', className)} style={style}>
        <input
          type="checkbox"
          className="form-check-input"
          name={name}
          id={inputId}
          ref={ref}
          {...props}
        />
        <Check className="form-check-icon" width="10" height="10" />
        {label && (
          <label className="form-check-label" htmlFor={inputId}>
            {label}
            &nbsp;
            {customIconTooltip && (
              <OverlayTrigger
                placement="top"
                overlay={(
                  <Tooltip id={`tooltip-${uuidv4()}`}>
                    {customIconTooltip}
                  </Tooltip>
                )}
              >
                {customIcon}
              </OverlayTrigger>
            )}
            {!customIconTooltip && customIcon}
          </label>
        )}
      </div>
    )
  },
);

export default Checkbox;
