import React, { useState, ReactElement } from 'react';

import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useSWRMutation from 'swr/mutation';

interface IProps {
  smsAutoReplyId: string
  className?: string
  onSuccess?: () => void
  onDelete?: (url, { arg }: {arg: { replyId: string }}) => void
}

function DeleteActionButton({
  smsAutoReplyId, className, onSuccess, onDelete,
}: IProps): ReactElement {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [interactionDisabled, setInteractionDisabled] = useState<boolean>(false);

  const { trigger: deleteReply } = useSWRMutation('deletesmsautoreply', onDelete, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      toast.success('Sms auto reply successfully deleted');
      setInteractionDisabled(false);
      onSuccess();
    },
  });

  const handleDelete = () => {
    setInteractionDisabled(true);
    deleteReply({ replyId: smsAutoReplyId });
  }

  return (
    <>
      <a
        href="/sms-auto-reply"
        className={className}
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true)
        }}
      >
        Delete
      </a>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="DeleteActionButtonModal"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton={!interactionDisabled} />
        <Modal.Body>
          <FontAwesomeIcon
            icon="trash-alt"
            size="4x"
            color="#FF7052"
          />

          <div className="message">
            <h4>Are you sure?</h4>
            <p>
              once deleted this action cannot be undone.
            </p>
          </div>

          <button
            type="button"
            className="btn btn-block btn-danger"
            disabled={interactionDisabled}
            onClick={() => {
              setShowModal(false);
              handleDelete();
            }}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-block btn-secondary"
            disabled={interactionDisabled}
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DeleteActionButton;
