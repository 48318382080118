import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { ReactComponent as LabelsSvg } from 'styles/images/labels.svg';
import Input from 'components/Inputs/Input';

interface AddKeywordModalProps {
  onSave: (keyword: string) => void
  onCancel: () => void
}

export const AddKeywordModal: React.FC<AddKeywordModalProps> = ({
  onSave,
  onCancel,
}) => {
  const [val, setVal] = React.useState('');

  const handleSave = () => {
    onSave(val);
  }

  return (
    <Modal
      show
      onHide={onCancel}
      className="view-labels-modal"
    >
      <Modal.Header
        closeButton
      >
        <LabelsSvg />
        <Modal.Title>
          Keyword
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Input
          type="text"
          placeholder="Enter keyword"
          label="Please enter a new keyword:"
          className="mb-0"
          value={val}
          maxLength={16}
          onChange={(e) => {
            setVal(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            className="btn btn-dark square"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary square ml-2"
            onClick={handleSave}
            disabled={!val.trim()}
          >
            Add
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
