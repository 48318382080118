import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SmsAutoReplyIndexPage from 'pages/SmsAutoReply/SmsAutoReplyIndex';
import SmsAutoReplyEditPage from 'pages/SmsAutoReply/SmsAutoReplyEditPage';

function SmsAutoReplyRoutes() {
  return (
    <Switch>
      <Route path="/sms-auto-reply/:replyId/edit" component={SmsAutoReplyEditPage} />
      <Route path="/sms-auto-reply/create" component={SmsAutoReplyEditPage} />
      <Route path="/sms-auto-reply" component={SmsAutoReplyIndexPage} />
    </Switch>
  );
}

export default SmsAutoReplyRoutes;
