import {
  BroadcastFormResponse, BroadcastFormRequestBody, IBroadcastFormsPayload, GetBroadcastFormsParams,
  BroadcastForm,
  BroadcastFormResultResponse,
  EnterpriseBroadcastFormGeneratorModelEdit,
  EnterpriseBroadcastFormGeneratorModel,
  BroadcastFormResendResponse,
  GetEbfGeneratorParams,
  IEbfGeneratorsPayload,
  EnterpriseFormOneTimeEventModelEdit,
  EnterpriseFormOneTimeEventModel,
  BroadcastFormQuestion,
  EnterpriseFormTriggeredEventModelEdit,
  EnterpriseFormTriggeredEventModel,
  EbfAccountEventsConfig,
  CreateBroadcastForm,
} from 'types/BroadcastForm';
import { IndexPayload } from 'types/request';
import API, { AxiosRequestOptions } from './API';

type SetLanguagePayload = {
  language: string
};

export const getFormSessionById = async (
  formId: string, sessionId: string, targetLanguage?: string, options?: AxiosRequestOptions,
): Promise<BroadcastFormResponse> => {
  const response = await API.get<BroadcastFormResponse>(
    `/api/v2/BroadcastForms/${formId}/session/${sessionId}?targetLanguage=${targetLanguage}`,
    options,
  );
  return response.data;
};

export const submitBroadcastForm = async (
  formId: string, sessionId: string, payload: BroadcastFormRequestBody, options?: AxiosRequestOptions,
): Promise<void> => {
  await API.post<void>(`/api/v2/BroadcastForms/${formId}/session/${sessionId}`, payload, options);
};

export const submitLanguageForm = async (
  formId: string, sessionId: string, language: string, options?: AxiosRequestOptions,
): Promise<void> => {
  const payload: SetLanguagePayload = { language };
  await API.post<void>(`/api/v2/LanguageForm/${formId}/session/${sessionId}`, payload, options);
};

export const getForms = async (
  {
    searchField = '',
    searchValue = '',
    sortField = '',
    sortDirection = '',
    page,
    perpage,
    statusType,
    includeEnterpriseSurveys,
  }: GetBroadcastFormsParams) => {
  const params = {
    searchField: (searchField.length > 0 ? searchField : undefined),
    searchValue: (searchValue.trim().length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
    statusType,
    includeEnterpriseSurveys,
  };
  const response = await API.get<IndexPayload<IBroadcastFormsPayload>>(
    '/api/v2/BroadcastForms',
    { params },
  );
  return response.data;
};

export const getEbfGenerators = async (
  {
    searchField = '',
    searchValue = '',
    sortField = '',
    sortDirection = '',
    page,
    perpage,
    isActive,
  }: GetEbfGeneratorParams) => {
  const params = {
    searchField: (searchField.length > 0 ? searchField : undefined),
    searchValue: (searchValue.trim().length > 0 ? searchValue : undefined),
    sortField: (sortField.length > 0 ? sortField : undefined),
    sortDirection: (sortDirection.length > 0 ? sortDirection : undefined),
    page: (page || undefined),
    perpage: (perpage || undefined),
    isActive,
  };
  const response = await API.get<IndexPayload<IEbfGeneratorsPayload>>(
    '/api/v2/enterprise-broadcast-forms',
    { params },
  );
  return response.data;
};

export const completeBroadcastForm = async (
  formId: number, isComplete: boolean, options?: AxiosRequestOptions,
): Promise<void> => {
  await API.post<void>(`/api/v2/BroadcastForms/${formId}/complete`, { formId, isComplete }, options);
};

export async function broadcastFormDelete(formId: number): Promise<void> {
  await API.delete(`/api/v2/BroadcastForms/${formId}`);
}

export const addNewForm = async (
  form: CreateBroadcastForm, options?: AxiosRequestOptions,
): Promise<BroadcastForm> => {
  const response = await API.post<BroadcastForm>(
    '/api/v2/BroadcastForms',
    form,
    options,
  );
  return response.data;
};

export const addRecipientsByEventId = async (
  formId: number, eventId: number, options?: AxiosRequestOptions,
): Promise<void> => {
  await API.post<void>(`/api/v2/BroadcastForms/${formId}/recipients/${eventId}`, options);
};

export const getFormResultById = async (
  formId: string, options?: AxiosRequestOptions,
): Promise<BroadcastFormResultResponse> => {
  const response = await API.get<BroadcastFormResultResponse>(
    `/api/v2/BroadcastForms/${formId}/result`,
    options,
  );
  return response.data;
};

export const addGenerator = async (
  formType: string,
  model: EnterpriseBroadcastFormGeneratorModelEdit,
  options?: AxiosRequestOptions,
): Promise<EnterpriseBroadcastFormGeneratorModel> => {
  const response = await API.post<EnterpriseBroadcastFormGeneratorModel>(
    `/api/v2/enterprise-broadcast-forms?formType=${formType}`,
    model,
    options,
  )
  return response.data
}

export const getGeneratorById = async (
  generatorId: number,
  options?: AxiosRequestOptions,
): Promise<EnterpriseBroadcastFormGeneratorModel> => {
  const response = await API.get<EnterpriseBroadcastFormGeneratorModel>(
    `/api/v2/enterprise-broadcast-forms/${generatorId}`,
    options,
  )
  return response.data
}

export const updateGenerator = async (
  generatorId: number,
  model: EnterpriseBroadcastFormGeneratorModelEdit,
  options?: AxiosRequestOptions,
): Promise<EnterpriseBroadcastFormGeneratorModel> => {
  const response = await API.put<EnterpriseBroadcastFormGeneratorModel>(
    `/api/v2/enterprise-broadcast-forms/${generatorId}`,
    model,
    options,
  )
  return response.data
}

export const getFormResendById = async (
  formId: string, options?: AxiosRequestOptions,
): Promise<BroadcastFormResendResponse> => {
  const response = await API.get<BroadcastFormResendResponse>(
    `/api/v2/BroadcastForms/${formId}/resend`,
    options,
  )
  return response.data
}

export const addOneTimeEvent = async (
  generatorId: number,
  model: EnterpriseFormOneTimeEventModelEdit,
  options?: AxiosRequestOptions,
): Promise<EnterpriseFormOneTimeEventModel> => {
  const response = await API.post<EnterpriseFormOneTimeEventModel>(
    `/api/v2/enterprise-broadcast-forms/${generatorId}/one-time-events`,
    model,
    options,
  )
  return response.data
}

export const updateOneTimeEvent = async (
  oneTimeEventId: number,
  model: EnterpriseFormOneTimeEventModelEdit,
  options?: AxiosRequestOptions,
): Promise<EnterpriseFormOneTimeEventModel> => {
  const response = await API.put<EnterpriseFormOneTimeEventModel>(
    `/api/v2/enterprise-broadcast-forms/one-time-events/${oneTimeEventId}`,
    model,
    options,
  )
  return response.data
}

export const deleteOneTimeEventById = async (
  oneTimeEventId: number,
  options?: AxiosRequestOptions,
): Promise<void> => {
  await API.delete<void>(
    `/api/v2/enterprise-broadcast-forms/one-time-events/${oneTimeEventId}`,
    options,
  )
}

export const getOneTimeEvents = async (
  generatorId: number,
  options?: AxiosRequestOptions,
): Promise<EnterpriseFormOneTimeEventModel[]> => {
  if (!generatorId) {
    return []
  }
  const response = await API.get<EnterpriseFormOneTimeEventModel[]>(
    `/api/v2/enterprise-broadcast-forms/${generatorId}/one-time-events`,
    options,
  )
  return response.data
}

export const getEnterpriseSubaccounts = async (
  options?: AxiosRequestOptions,
): Promise<string[]> => {
  const response = await API.get<string[]>(
    '/api/v2/enterprise-broadcast-forms/subaccounts',
    options,
  )
  return response.data
}

export const debugGenerateTodaysEvents = async (
  options?: AxiosRequestOptions,
): Promise<string> => {
  const response = await API.post<string>(
    '/api/v2/enterprise-broadcast-forms/generateTodaysEvents',
    {},
    options,
  )
  return response.data
}

export const getFormQuestionsByFormType = async (
  formType: string, options?: AxiosRequestOptions,
): Promise<BroadcastFormQuestion[]> => {
  if (!formType) return [];
  const response = await API.get<BroadcastFormQuestion[]>(
    `/api/v2/BroadcastForms/FormType/Questions/${formType}`,
  );
  return response.data;
};

export const addTriggeredEvent = async (
  generatorId: number,
  model: EnterpriseFormTriggeredEventModelEdit,
  options?: AxiosRequestOptions,
): Promise<EnterpriseFormTriggeredEventModel> => {
  const response = await API.post<EnterpriseFormTriggeredEventModel>(
    `/api/v2/enterprise-broadcast-forms/${generatorId}/triggered-events`,
    model,
    options,
  )
  return response.data
}

export const updateTriggeredEvent = async (
  triggeredEventId: number,
  model: EnterpriseFormTriggeredEventModelEdit,
  options?: AxiosRequestOptions,
): Promise<EnterpriseFormTriggeredEventModel> => {
  const response = await API.put<EnterpriseFormTriggeredEventModel>(
    `/api/v2/enterprise-broadcast-forms/triggered-events/${triggeredEventId}`,
    model,
    options,
  )
  return response.data
}

export const deleteTriggeredEventById = async (
  triggeredEventId: number,
  options?: AxiosRequestOptions,
): Promise<void> => {
  await API.delete<void>(
    `/api/v2/enterprise-broadcast-forms/triggered-events/${triggeredEventId}`,
    options,
  )
}

export const getTriggeredEvents = async (
  generatorId: number,
  options?: AxiosRequestOptions,
): Promise<EnterpriseFormTriggeredEventModel[]> => {
  if (!generatorId) {
    return []
  }
  const response = await API.get<EnterpriseFormTriggeredEventModel[]>(
    `/api/v2/enterprise-broadcast-forms/${generatorId}/triggered-events`,
    options,
  )
  return response.data
}

export const getEbfAccountEventsConfig = async (
  options?: AxiosRequestOptions,
): Promise<EbfAccountEventsConfig> => {
  const response = await API.get<EbfAccountEventsConfig>(
    '/api/v2/enterprise-broadcast-forms/subaccounts/eventDatesConfig',
    options,
  )
  return response.data
}

export const checkProfileHasResponse = async (
  formId: string, profileId: string, options?: AxiosRequestOptions,
): Promise<boolean> => {
  if (!formId || !profileId) return false;
  const response = await API.get<boolean>(
    `/api/v2/BroadcastForms/${formId}/profile/${profileId}`,
    options,
  );
  return response.data;
};

export const addRecipient = async (
  formId: string, profileId: string, options?: AxiosRequestOptions,
): Promise<string> => {
  if (!formId || !profileId) return null;
  const response = await API.post<string>(
    `/api/v2/BroadcastForms/${formId}/recipient/${profileId}`,
    { },
    options,
  );
  return response.data;
};
