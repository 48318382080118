/* eslint-disable jsx-a11y/label-has-associated-control */

import React, {
  useState, ReactElement,
} from 'react';

import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Select, SearchInput } from 'components/FormControls';

export interface ISearchQuery {
  searchField?: string
  searchValue?: string
}

interface IProps {
  searchQuery: ISearchQuery
  onSearch: (searchQuery: ISearchQuery) => void
  onResetFilters: () => void
  totalItems: number
  itemsLoading?: boolean
  children?: React.ReactNode
}

function SmsAutoReplyFilter({
  searchQuery,
  onSearch,
  onResetFilters,
  totalItems,
  itemsLoading,
  children,
}: IProps): ReactElement {
  const {
    control, setValue, handleSubmit,
  } = useForm<ISearchQuery>({
    defaultValues: { ...searchQuery },
  });

  const [open, setOpen] = useState<boolean>(false);

  const onSearchSubmit = (data: ISearchQuery): void => {
    data.searchValue ? onSearch(data) : onSearch({});
  };

  const resetFilters = (): void => {
    setValue('searchValue', '');
    setValue('searchField', 'Keyword');
    onResetFilters();
    setOpen(false);
  };

  return (
    <div
      className="index-filter"
    >
      <div className="index-filter__mobile-toggle" role="presentation" onClick={() => setOpen(!open)}>
        Filtering
        <FontAwesomeIcon icon={open ? 'caret-down' : 'caret-right'} />
      </div>
      <form id="form" onSubmit={handleSubmit(onSearchSubmit)}>
        <div
          className={clsx('index-filter__main', open && 'open')}
        >
          <Select
            id="searchField"
            label="Search By"
            name="searchField"
            className="mb-2 mr-2"
            style={{ width: '240px' }}
            control={control}
            defaultValue="Keyword"
            options={[
              { label: 'Keywords', value: 'Keyword' },
              { label: 'Description', value: 'Description' },
              { label: 'Response', value: 'Response' },
            ]}
          />

          <div className="d-flex align-items-end mb-2" style={{ maxWidth: '100%' }}>
            <SearchInput
              id="searchValue"
              label="Search Keywords"
              placeholder="I am looking for..."
              name="searchValue"
              type="input"
              className="mb-0"
              control={control}
            />
            <button
              type="submit"
              className="btn btn-md btn-primary square ml-3"
            >
              Search
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-3 px-0"
              onClick={resetFilters}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="flex-grow-1 d-none d-md-block" />
        {itemsLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount loading-text">
              Loading...
            </div>
          </div>
        )}
        {!itemsLoading && (
          <div className="index-filter__item-count mb-2">
            <div className="itemCount">
              {`${totalItems} Keyword(s) In Total`}
            </div>
          </div>
        )}
        {children}
      </form>
    </div>
  );
}

export default SmsAutoReplyFilter;
